<template>
  <addons-integrations-settings>
    <template v-slot:name>
      {{ $t('integrationsSettingsTitle') }}
    </template>
    <template v-slot:main>
      <!--<integrations-pos />-->
      <integrations-google-food-menus />
    </template>
  </addons-integrations-settings>
</template>

<script>
import AddonsIntegrationsSettings from '@/components/AddonsIntegrationsSettings.vue'
// import IntegrationsPos from '@/components/integrations/IntegrationsPos.vue'
import IntegrationsGoogleFoodMenus from '@/components/integrations/IntegrationsGoogleFoodMenus.vue'

export default {
  name: 'Integrations',
  components: {
    AddonsIntegrationsSettings,
    // IntegrationsPos,
    IntegrationsGoogleFoodMenus,
  },
}
</script>
