<template>
  <b-overlay
    :show="isLoading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <b-card-title class="mb-0">
          {{ $t('addOnsGoogleFoodMenusName') }}
        </b-card-title>
        <b-badge
          v-if="!isLoading"
          :variant="isEnabled ? 'light-success' : 'light-danger'"
        >
          {{ isEnabled ? $t('addOnsAvailableTitle') : $t('addOnsNotAvailableTitle') }}
        </b-badge>
      </div>
      <p :class="['font-small-3 line-height-inherit', { 'mb-0': isLoading }]">
        {{ $t('addOnsGoogleFoodMenusDescription') }}
      </p>
      <template v-if="!isLoading">
        <template v-if="isEnabled">
          <div class="d-flex justify-content-between align-items-center mt-2 mb-1">
            <div class="font-weight-bold">
              {{ $t('addOnsGoogleFoodMenusActivateName') }}
            </div>
            <b-form-checkbox
              v-model="isActive"
              :disabled="isSaving || isRemoving"
              switch
              inline
              class="mr-0"
            />
          </div>
          <p class="font-small-3 line-height-inherit">
            {{ $t('addOnsSaveTextForIntegration') }}
          </p>
          <div
            v-if="isActive && account"
            class="mt-2 mb-2"
          >
            <b-row class="mb-1">
              <b-col>
                {{ $t('addOnsGoogleFoodMenusAccountLabel') }}: <b class="text-primary">{{ account.accountName }}</b>
              </b-col>
              <b-col
                cols="auto"
                class="d-flex"
              >
                <b-spinner
                  v-if="isRemoving"
                  variant="primary"
                  class="spinner-20"
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.top="$t('Delete')"
                  :class="[{ 'text-danger': !isSaving }, { 'feather-disabled': isSaving }]"
                  icon="TrashIcon"
                  size="20"
                  role="button"
                  @click="onClickRemove"
                />
              </b-col>
            </b-row>
            <b-row v-if="optionsLocation.length">
              <b-col>
                <b-form-group
                  :label="$t('addOnsGoogleFoodMenusLocationLabel')"
                  class="mb-0"
                >
                  <v-select
                    v-model="location"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :disabled="isSaving || isRemoving"
                    :options="optionsLocation"
                  >
                    <template #no-options>
                      {{ $t('The list is empty') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('addOnsGoogleFoodMenusMenuLabel')"
                  class="mb-0"
                >
                  <v-select
                    v-model="menu"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :clearable="false"
                    :disabled="isSaving || isRemoving"
                    :options="menus"
                  >
                    <template #no-options>
                      {{ $t('The list is empty') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-else
              class="text-danger"
            >
              <i18n
                path="addOnsGoogleBusinessProfilesNotFound"
                tag="p"
                class="mb-0"
              >
                <template v-slot:slot>
                  <a
                    href="https://business.google.com/create"
                    target="_blank"
                  >
                    https://business.google.com/create
                  </a>
                </template>
              </i18n>
            </div>
          </div>
          <b-button
            variant="primary"
            :disabled="(!isActive && !account) || isSaving || isRemoving"
            @click="onClickSave"
          >
            <b-spinner
              v-if="isSaving"
              class="d-flex"
              small
            />
            <template v-else>
              {{ !account ? $t('Connect') : $t('addOnsSaveName') }}
            </template>
          </b-button>
        </template>
        <template v-else>
          <hr>
          <i18n
            path="addOnsNotAvailableText"
            tag="p"
            class="font-small-3 line-height-inherit mb-0"
          >
            <template v-slot:slot>
              <b>{{ $t('addOnsGoogleFoodMenusName') }}</b>
            </template>
            <template v-slot:slot2>
              <router-link :to="{ name: 'add-ons' }">
                {{ $t('addOnsFullName') }}
              </router-link>
            </template>
          </i18n>
        </template>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardTitle,
  BBadge,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinGqlGoogleFoodMenus from '@/mixins/gql/google-food-menus'

import UPDATE_RESTAURANT from '@/gql/mutation/restaurant/updateRestaurant.gql'

export default {
  name: 'IntegrationsGoogleFoodMenus',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BCard,
    BCardTitle,
    BBadge,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
  },
  mixins: [mixinGqlGoogleFoodMenus],
  data() {
    return {
      isLoading: true,
      isActive: false,
      isSaving: false,

      isRemoving: false,

      account: null,
      optionsAccount: [],
      location: null,
      optionsLocations: [],
      menu: null,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      subscription: 'billing/subscription',
      isMenusFetched: 'menuManagement/isMenusFetched',
      menus: 'menuManagement/menus',
    }),
    isEnabled() {
      const name = 'Google Food Menus'
      return !!(
        this.subscription
        && this.subscription.addons.length
        && this.subscription.addons.find(i => i.name === name)
        && this.subscription.addons.find(i => i.name === name).enabled
      )
    },
    optionsLocation() {
      if (!this.account) {
        return []
      }
      return this.optionsLocations.find(i => i.name === this.account.name).locations
    },
  },
  watch: {
    subscription() {
      this.setData()
    },
    isMenusFetched() {
      this.setData()
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
      getMenus: 'menuManagement/getMenus',
    }),
    async onClickRemove() {
      this.isRemoving = true

      try {
        await this.removeAccountLinkMutation(this.restaurant.id)

        const googleFoodMenusSettings = {
          enabled: false,
        }
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            googleFoodMenusSettings,
          },
        })
        this.updateRestaurant({ googleFoodMenusSettings })
        this.isActive = false

        this.account = null
        this.optionsAccount = []
        this.location = null
        this.optionsLocations = []
        this.menu = null

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isRemoving = false
      }
    },
    async setData() {
      if (!this.subscription) {
        return
      }
      if (!this.isMenusFetched) {
        this.getMenus()
        return
      }

      const response = await this.getAccountsListQuery(this.restaurant.id)
      this.optionsAccount = response.data.getAccountsList
      if (this.optionsAccount.length) {
        // eslint-disable-next-line prefer-destructuring
        this.account = this.optionsAccount[0]

        const responses = await Promise.all(
          this.optionsAccount.map(i => this.getLocationsListQuery(this.restaurant.id, i.name)),
        )
        this.optionsLocations = responses
          .map((i, index) => ({ name: this.optionsAccount[index].name, locations: i.data.getLocationsList }))
        // eslint-disable-next-line prefer-destructuring
        const locations = this.optionsLocations.find(i => i.name === this.account.name).locations
        if (locations.length) {
          // eslint-disable-next-line prefer-destructuring
          this.location = locations[0]
        }
      }

      this.isActive = this.restaurant.googleFoodMenusSettings.enabled

      this.isLoading = false
    },
    onClickSave() {
      if (this.account) {
        this.saveSettings()
      } else {
        this.connect()
      }
    },
    async saveSettings() {
      this.isSaving = true

      const googleFoodMenusSettings = {
        ...this.restaurant.googleFoodMenusSettings,
        enabled: this.isActive,
      }

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            googleFoodMenusSettings,
          },
        })

        this.updateRestaurant({ googleFoodMenusSettings })

        if (googleFoodMenusSettings.enabled && this.account && this.location && this.menu) {
          await this.syncMenuMutations(
            this.restaurant.organization.id,
            this.restaurant.id,
            this.account.name,
            this.location.name,
            this.menu.id,
          )
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSaving = false
      }
    },
    async connect() {
      this.isSaving = true

      const googleFoodMenusSettings = {
        ...this.restaurant.googleFoodMenusSettings,
        enabled: true,
      }

      try {
        const responses = await Promise.all([
          this.getAuthUrlForSyncQuery(this.restaurant.organization.id, this.restaurant.id),
          this.$apollo.mutate({
            mutation: UPDATE_RESTAURANT,
            variables: {
              updateRestaurantId: this.restaurant.id,
              googleFoodMenusSettings,
            },
          }),
        ])

        window.open(responses[0].data.getAuthUrlForSync, '_self')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/libs/vue-select.scss'
</style>
