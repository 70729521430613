import GET_AUTH_URL_FOR_SYNC from '@/gql/query/getAuthUrlForSync.gql'
import GET_ACCOUNTS_LIST from '@/gql/query/getAccountsList.gql'
import GET_LOCATIONS_LIST from '@/gql/query/getLocationsList.gql'

import REMOVE_ACCOUNT_LINK from '@/gql/mutation/removeAccountLink.gql'
import SYNC_MENU from '@/gql/mutation/syncMenu.gql'

export default {
  methods: {
    getAuthUrlForSyncQuery(organization, restaurant) {
      return this.$apollo.query({
        query: GET_AUTH_URL_FOR_SYNC,
        variables: {
          organization,
          restaurant,
        },
      })
    },
    getAccountsListQuery(restaurant) {
      return this.$apollo.query({
        query: GET_ACCOUNTS_LIST,
        variables: {
          restaurant,
        },
      })
    },
    getLocationsListQuery(restaurant, name) {
      return this.$apollo.query({
        query: GET_LOCATIONS_LIST,
        variables: {
          restaurant,
          name,
        },
      })
    },

    removeAccountLinkMutation(restaurant) {
      return this.$apollo.query({
        query: REMOVE_ACCOUNT_LINK,
        variables: {
          restaurant,
        },
      })
    },
    syncMenuMutations(organization, restaurant, accountName, locationName, menuId) {
      return this.$apollo.mutate({
        mutation: SYNC_MENU,
        variables: {
          organization,
          restaurant,
          accountName,
          locationName,
          menuId,
        },
      })
    },
  },
}
